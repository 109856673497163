import { useTheme } from "@emotion/react"
import { Box, Button, ButtonBase, Card, CircularProgress, CssBaseline, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

import { getActivity } from "../game/ActivityFuncs"
import { GameFunctions } from "../game/GameFunctionsContext"
import { createBaseCharacterSave, getDiscordInfo, getUserSaveFromCloud, loadPlayerData, verifyDiscordCode } from "../store/helperFuncs"
import { setHasChosenSave, setIsCloudSave, setSaveSlotToUse } from "../store/miscReducer"

const LOCALHOST_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&scope=identify"
const DEV_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=https%3A%2F%2Fdev.goosebanana.com%2Flogin&scope=identify"
const PROD_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=https%3A%2F%2Fidle.goosebanana.com%2Flogin&scope=identify"

const DiscordSave = () => {

    const GameFuncs = React.useContext(GameFunctions)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const localSaves = loadPlayerData()

    const [save, setSave] = React.useState(-1)

    const theme = useTheme()

    const didEffectRun = React.useRef(false)
    React.useEffect(() => {

        if (didEffectRun.current) return

        const runOnLoad = async () => {

            let cloudSaveData = await getUserSaveFromCloud()
            console.log("AAAAA")
            console.log(cloudSaveData)

            setSave(cloudSaveData)

        }

        runOnLoad()
        //console.log(data)

        return () => didEffectRun.current = true

    }, [])

    const importSaveClicked = (saveSlot) => {

        let tmpSaveData = {
            ...(createBaseCharacterSave())
        }
        console.log(tmpSaveData)

        if (saveSlot > 0) {
            console.log("Using save data from slot " + saveSlot)
            tmpSaveData = {
                ...tmpSaveData,
                ...(localSaves[saveSlot])
            }

        }

        GameFuncs.replaceState(tmpSaveData)
        if (window.location.pathname !== "/groggers") navigate("/")
        dispatch(setSaveSlotToUse(saveSlot))
        dispatch(setHasChosenSave(true))
        dispatch(setIsCloudSave(true))
        localStorage.setItem("lastCloudSave", tmpSaveData.lastSaveTime)
        //console.log(localSaves[saveSlot])
    }

    const loadCloudSave = async () => {

        let cloudSaveData = await getUserSaveFromCloud()
        let tmpSaveData = {
            ...(createBaseCharacterSave()),
            ...cloudSaveData
        }

        GameFuncs.replaceState(tmpSaveData)
        if (window.location.pathname !== "/groggers") navigate("/")
        dispatch(setHasChosenSave(true))
        dispatch(setIsCloudSave(true))
        localStorage.setItem("lastCloudSave", tmpSaveData.lastSaveTime)

    }

    return (
        <>
            {
                (save === -1) ?
                    <>
                        <CircularProgress color="info" />
                    </>
                    :
                    <></>
            }
            {
                (save === null) ?

                    <Grid container spacing={1}>
                        <Grid xs={12}>
                            <Typography>No cloud save</Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Button onClick={() => { importSaveClicked(0) }}>New Save</Button>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={4}>
                                <Button onClick={() => { importSaveClicked(1) }}>Import 1</Button>
                            </Grid>
                            <Grid xs={4}>
                                <Button onClick={() => { importSaveClicked(2) }}>Import 2</Button>
                            </Grid>
                            <Grid xs={4}>
                                <Button onClick={() => { importSaveClicked(3) }}>Import 3</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    :
                    <></>
            }
            {
                (save !== -1 && save !== null) ?

                    <Grid container spacing={1}>
                        <Grid xs={12}>
                            <ButtonBase sx={{ justifyItems: "center", alignItems: "center", width: "1", margin: "8px 0px" }} onClick={loadCloudSave}>
                                <Card sx={{ bgcolor: theme.palette.primary.main, paddingY: "6px", width: "1" }}>
                                    <Typography>Cloud Save</Typography>
                                    
                                                <Typography>{(save.activity.currentActivity === null) ? "No current task" : getActivity(save.activity.currentActivity).name}</Typography>
                                                <Typography>{(new Date(save.lastSaveTime)).toLocaleString()}</Typography>
                                </Card>
                            </ButtonBase>
                        </Grid>

                    </Grid>

                    :
                    <></>
            }
        </>
    )
}

const DiscordCard = () => {

    const didEffectRun = React.useRef(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const [discordData, setDiscordData] = React.useState(-2)
    const [discordSaves, setDiscordSaves] = React.useState(null)

    let saves = null

    React.useEffect(() => {

        if (didEffectRun.current) return

        const runOnLoad = async () => {

            let code = searchParams.get("code")
            if (code !== null) {
                console.log(code)
                const codeVerification = await verifyDiscordCode(code)
                console.log(codeVerification)

            }

            const discordInfo = await getDiscordInfo(localStorage.getItem("uuid"))

            console.log(discordInfo)

            if (discordInfo) {
                console.log("Discord login OK")
                setDiscordData(discordInfo)
            } else {
                console.log("Discord not logged in")

                setDiscordData(-1)
            }

        }

        runOnLoad()
        //console.log(data)

        return () => didEffectRun.current = true

    }, [])

    return (
        <Card sx={{ padding: "16px", textAlign: "center" }}>
            {
                (discordData === -2) ?
                    <>
                        <CircularProgress color="info" />
                    </>
                    :
                    <></>
            }
            {
                (discordData === -1) ?
                    <>
                        <Typography sx={{ marginBottom: "8px" }}>Discord Login</Typography>
                        <Button href={DEV_LOGIN_URI}>Login to Discord</Button>
                    </>
                    :
                    <></>
            }
            {
                (discordData.id) ?
                    <>
                        <img src={`https://cdn.discordapp.com/avatars/${discordData.id}/${discordData.avatar}`} style={{ width: "64px", borderRadius: "32px" }} />
                        <Typography>{discordData.global_name}</Typography>
                        <Typography>Cloud Save</Typography>
                        <DiscordSave />
                    </>
                    :
                    <></>
            }


        </Card>
    )

}

const LocalSavesCard = () => {

    const GameFuncs = React.useContext(GameFunctions)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const localSaves = loadPlayerData()

    const theme = useTheme()

    console.log(localSaves)

    const saveClicked = (saveSlot, saveData) => {
        console.log(saveSlot, saveData)
        let tmpSaveData = {
            ...(createBaseCharacterSave()),
            ...saveData
        }
        console.log(tmpSaveData)

        GameFuncs.replaceState(tmpSaveData)
        if (window.location.pathname !== "/groggers") navigate("/")
        dispatch(setSaveSlotToUse(saveSlot))
        dispatch(setHasChosenSave(true))
    }

    return (
        <Card sx={{ padding: "16px", textAlign: "center" }}>
            <Typography>Local Saves</Typography>
            <Grid container>
                {Object.values(localSaves).map((data, index) => {
                    console.log(index, data)
                    return <Grid xs={12} key={index}>
                        <ButtonBase sx={{ justifyItems: "center", alignItems: "center", width: "1", margin: "8px 0px" }} onClick={() => { saveClicked(index + 1, data) }}>
                            <Card sx={{ bgcolor: theme.palette.primary.main, paddingY: "6px", width: "1" }}>
                                <Typography>Save {index + 1}</Typography>
                                {
                                    (Object.keys(data).length === 0) ?
                                        <>
                                            <Typography>Blank Save</Typography>
                                        </>
                                        :
                                        <>
                                            <Typography>{(data.activity.currentActivity === null) ? "No current task" : getActivity(data.activity.currentActivity).name}</Typography>
                                            <Typography>{(new Date(data.lastSaveTime)).toLocaleString()}</Typography>
                                        </>
                                }
                            </Card>
                        </ButtonBase>
                    </Grid>

                })}
            </Grid>
        </Card>
    )

}

const SaveLoad = () => {

    return (
        <>
            <CssBaseline />
            <Box component="main" sx={{
                padding: "0px", width: "100%", maxWidth: "100vw", height: 1, maxHeight: "100vh"
            }}>
                <Grid container sx={{ width: "100%", maxWidth: "800px", height: 1, margin: "auto" }}>
                    <Grid container xs={12}>
                        <Grid xs={12} lg={6} sx={{ maxWidth: "360px", margin: "auto" }}>
                            <DiscordCard />

                        </Grid>
                        <Grid xs={12} lg={6} sx={{ maxWidth: "360px", margin: "auto" }}>
                            <LocalSavesCard />
                        </Grid>
                    </Grid>

                </Grid>

            </Box>
        </>
    )
}
export default SaveLoad